// Body, html
body, html {
  min-width: 320px;
  font-family: $ubuntu-regular;
  font-size: 16px;
  color: $dark-1;
}

*, .btn {
  outline: none !important;
  outline: 0 !important;
  &:active, &:focus, &:visited, &:hover {
    outline: none !important;
    outline: 0 !important;
  }
}

h1,h2,h3,h4,h5,h6,ul,li,p {
  margin: 0;
  padding: 0;
  i {
    display: inline-block;
    vertical-align: middle;
  }
}

a {
  color: currentColor;
  text-decoration: none;
  &:hover {
    color: currentColor;
    text-decoration: none;
  }
}

h2 {
  font-family: $ubuntu-bold;
  font-size: 2.5rem;
  letter-spacing: .25rem;
}
h3 {
  font-family: $ubuntu-bold;
  font-size: 1.25rem;
  i {
    margin-right: 15px;
  }
}

p {
  font-size: 1rem;
  margin-top: 20px;
}

.btn_red {
  .icon-down-arr {
    display: inline-block;
    transform-origin: 50% 50%;
    transition: all .3s ease-in-out;
  }
  &.clicked {
    .icon-down-arr {
      transform: translate(0, 3px) rotateX(-180deg);
    }
  }
  &.vacations {
    .icon-down-arr {
      margin-top: -7px;
    }
    &.clicked {
      .icon-down-arr {
        transform: translate(0, 9px) rotateX(-180deg);
      }
    }
  }
}

// Slick slider
.slick-dotted.slick-slider {
  margin-bottom: 0;
}

// Bootstrap container width for wide screens
@media screen and (min-width: 1470px) {
  .container {
    max-width: 1440px;
  }
}

.pickmeup {
  background: rgb(50, 50, 50);
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.25);
  z-index: 9999;
  .pmu-instance {
    height: 15em;
  }
  .pmu-button.pmu-month, .pmu-day-of-week div {
    font-family: $ubuntu-regular;
    font-size: 16px;
    color: #fff;
    background-color: transparent;
    margin: 0;
  }
  .pmu-button.pmu-prev, .pmu-button.pmu-next {
    background-color: transparent;
  }
  .pmu-button.pmu-not-in-month {
    opacity: 0;
  }
  .pmu-button {
    font-family: $ubuntu-regular;
    font-size: 16px;
    color: #fff;
    background-color: #2a2c2f;
    border: 1px solid rgb(50, 50, 50);
  }
}


.z-top {
  z-index: 1040;
}

.modal-dialog {
  max-width: 100% !important;
  .doctor {
    background-color: #fff;
  }
}

.modal-content {
  background-color: transparent;
  border: none;
  .close {
    position: absolute;
    right: -30px;
    top: -30px;
    width: 27px;
    height: 27px;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    span {
      display: block;
      position: relative;
      font-size: 3rem;
      font-weight: 100;
      top: -1.5rem;
    }
  }
}

.carousel-ctrl-btn {
  z-index: 9999;
}

.modal-body {
  .dark-text-block {
    padding-left: 3rem;
    padding-right: 3rem;    
  }
}

.preloader {
  display: flex;
  top: 0;
  left: 0;
  min-height: 100vh;
  z-index: 999999;
  // background-image: url(../img/wall.jpg);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  video {
    display: block;
    width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 991px) {
  
}

.modal {
  z-index: 9999;
}

.min-h-8 {
  min-height: 8rem;
}

@media screen and (max-width: 992px) {
  .min-h-8 {
    min-height: initial;
  } 
}