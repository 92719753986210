// Ubuntu light
@font-face {
  font-family: "Ubuntu Light";
  src: url("../fonts/UbuntuLight.ttf") format("truetype");
}
// Ubuntu regular
@font-face {
  font-family: "Ubuntu Regular";
  src: url("../fonts/UbuntuRegular.ttf") format("truetype");
}
// Ubuntu bold
@font-face {
  font-family: "Ubuntu Bold";
  src: url("../fonts/UbuntuBold.ttf") format("truetype");
}

// Iconmoon
@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?62tmyj');
  src:  url('../fonts/icomoon.eot?62tmyj#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?62tmyj') format('truetype'),
    url('../fonts/icomoon.woff?62tmyj') format('woff'),
    url('../fonts/icomoon.svg?62tmyj#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}